.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 40px;
}

.footer__copyright {
  opacity: 0.6;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.83;
}

@media (max-width: 900px) {
  .footer {
    padding: 30px;
  }
}
