.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: absolute;
  box-sizing: border-box;
  padding-top: 48px;
  max-width: 90%;
  z-index: 2;
  position: relative;
}

.header__title {
  text-align: center;
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: Mayak;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.14;
}

.header__phone {
  margin: 0 0 0 auto;
  text-align: center;
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: Mayak;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.14;
}

@media (max-width: 900px) {
  .header {
    padding-top: 40px;
  }

  .header__title {
    font-size: 18px;
    line-height: 1.11;
  }

  .header__phone {
    font-size: 18px;
    line-height: 1.11;
  }
}