.text-template {
  width: 100%;
  display: grid;
  grid-template-columns: 270px 1fr;
  align-items: start;
  gap: 30px;
  box-sizing: border-box;
  padding: 80px 120px 0;
}

.text-template_type_with-link {
  padding: 120px 120px 0;
}

.text-template__par-box {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
}

.text-template__par {
  color: var(--accent-color);
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}

.text-template__divider {
  width: 100%;
  height: 0.4px;
  background: var(--border-color);
  opacity: 0.8;
}

.text-template__content {
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.text-template__title {
  font-size: 54px;
  font-weight: 500;
  line-height: 1.26;
  letter-spacing: 2.16px;
  text-transform: uppercase;
  padding-bottom: 60px;
  margin-top: -8px;
}

.text-template__text {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.36;
  opacity: 0.7;
}

.text-template__link-btn {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 22px;
  border-radius: 80px;
  background: var(--container-bg);
  box-sizing: border-box;
  padding: 10px 17px 10px 24px;
  margin-top: 40px;
}

.text-template__link-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

.text-template__link-arrow {
  width: 22px;
  height: 20px;
  transform: rotate(-90deg);
}

.text-template__link-arrow-fill {
  fill: var(--light-neutral-color);
}

@media (max-width: 1150px) {
  .text-template {
    padding: 80px 50px 0;
  }

  .text-template_type_with-link {
    padding: 120px 50px 0;
  }

  .text-template__content {
    max-width: 500px;
  }

  .text-template__title {
    font-size: 40px;
  }
}

@media (max-width: 900px) {
  .text-template {
    grid-template-columns: 180px 1fr;
    gap: 20px;
    padding: 50px 50px 0;
  }

  .text-template_type_with-link {
    padding: 50px 50px 0;
  }

  .text-template__par-box {
    gap: 20px;
  }

  .text-template__par {
    font-size: 10px;
    line-height: 1.2;
    letter-spacing: 1.2px;
  }

  .text-template__divider {
    height: 0.2px;
  }

  .text-template__title {
    font-size: 26px;
    line-height: 1.31;
    letter-spacing: 1.04px;
    padding-bottom: 30px;
  }

  .text-template__text {
    font-size: 14px;
    line-height: 1.43;
    margin-top: -4px;
  }

  .text-template__link-btn {
    margin-top: 30px;
  }

  .text-template__link-text {
    font-size: 16px;
    line-height: 1.5;
  }
}

@media (max-width: 600px) {
  .text-template {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .text-template__par-box {
    gap: 12px;
  }

  .text-template__content {
    max-width: 100%;
  }

  .text-template__link-btn {
    width: 100%;
  }
}
