.imgs-grid {
  width: 114.44%;
  display: flex;
  align-self: flex-end;
  position: relative;
  margin-top: 120px;
}

.imgs-grid__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.imgs-grid__item {
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.imgs-grid__img {
  width: 100%;
}

.imgs-grid__icon {
  width: 33.98%;
  height: 33.98%;
  position: absolute;
}

.imgs-grid__icon-fill {
  fill: var(--text-color);
}

@media (max-width: 900px) {
  .imgs-grid {
    margin-top: 50px;
  }
}

@media (max-width: 600px) {
  .imgs-grid {
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
  }

  .imgs-grid__list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'qrcode coals'
      'moon astronaut';
  }

  .imgs-grid__item:first-of-type {
    grid-area: moon;
  }

  .imgs-grid__item:nth-of-type(2) {
    grid-area: qrcode;
  }

  .imgs-grid__item:nth-of-type(3) {
    grid-area: coals;
  }

  .imgs-grid__item:last-of-type {
    grid-area: astronaut;
  }
}
