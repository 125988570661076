.links {
  width: 100%;
  max-width: 900px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-top: 32px;
}

.links__logo {
  width: 146px;
  margin-left: -16px;
}

.links__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: auto;
  box-sizing: border-box;
  padding: 0 16px;
  margin-top: 32px;
}

.links__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  background: var(--opacity-bg);
  backdrop-filter: blur(4.5px);
  box-sizing: border-box;
  padding: 16px;
}

.links__item {
  width: 100%;
  border-top: 1px solid var(--border-opacity-color);
  box-sizing: border-box;
  padding-top: 16px;
}

.links__item:first-of-type {
  border-top: none;
  padding-top: 0;
}

.links__link {
  display: flex;
  align-items: center;
  gap: 16px;
}

.links__link-icon {
  width: 20px;
  height: 20px;
}

.links__link-icon-fill {
  fill: var(--neutral-icons-color);
}

.links__link-text {
  color: var(--neutral-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
}

.links__btn {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--accent-color);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.09;
  border-radius: 12px;
  border: 2px solid var(--accent-color);
  margin-top: 16px;
}

.links__footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 30px;
  margin-top: 10px;
}

.links__footer-bg {
  width: 180px;
  height: 398px;
  position: absolute;
  top: 0;
  z-index: 0;
}

.links__footer-bg-stroke {
  stroke: var(--accent-color);
}

.links__footer-gradient {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(30, 30, 45, 0) 2.68%,
    var(--bg-color) 57.3%
  );
  box-sizing: border-box;
  padding: 91px 62px 30px;
  position: relative;
  z-index: 1;
}

.links__footer-text {
  opacity: 0.54;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
}

.links__bg-img {
  width: 506px;
  position: absolute;
  top: -135px;
  right: -252px;
  background: linear-gradient(
    180deg,
    rgba(21, 21, 34, 0.03) 36.47%,
    var(--bg-color) 66.4%
  );
  mix-blend-mode: lighten;
  z-index: -2;
}

.links__circle-box {
  width: 462px;
  aspect-ratio: 1/1;
  position: absolute;
  z-index: -1;
}

.links__circle-box_position_left {
  top: -176px;
  left: -370px;
}

.links__circle-box_position_center {
  top: -292px;
  left: -163px;
}

.links__circle {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 0.4px solid var(--text-color);
  opacity: 0.3;
}

.links__fill-circle {
  width: 10px;
  aspect-ratio: 1/1;
  position: absolute;
  top: 341px;
  right: 25px;
  border-radius: 100%;
  background: var(--text-color);
}

.links__smoke-bg {
  width: 649px;
  position: absolute;
  top: 20px;
  left: -341px;
  mix-blend-mode: plus-lighter;
  z-index: -3;
}
