.banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-top: 752px;
}

.banner__img {
  width: 978px;
  position: absolute;
  top: -150px;
  background: linear-gradient(
    180deg,
    rgba(21, 21, 34, 0.03) 36.47%,
    var(--bg-color) 66.4%
  );
  mix-blend-mode: lighten;
  z-index: -3;
}

.banner__logo {
  width: 188px;
  height: 314px;
}

.banner__logo-fill-top {
  fill: var(--light-neutral-color);
}

.banner__logo-fill-bottom {
  fill: var(--neutral-color);
}

.banner__arrow-box {
  width: 31px;
  height: 32px;
  display: flex;
  margin-top: 80px;
}

.banner__arrow {
  width: 100%;
  height: 100%;
}

.banner__arrow-fill {
  fill: var(--light-neutral-color);
}

.banner__bg {
  width: 1440px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: -1;
}

.banner__circle-box {
  width: 1166px;
  aspect-ratio: 1/1;
  position: absolute;
}

.banner__circle-box_position_left {
  top: -434px;
  left: -715px;
}

.banner__circle-box_position_center {
  width: 944px;
  top: 68px;
}

.banner__circle-box_position_right {
  top: 613px;
  left: 610px;
}

.banner__circle {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 0.4px solid var(--text-color);
  opacity: 0.3;
}

.banner__fill-circle {
  aspect-ratio: 1/1;
  position: absolute;
  border-radius: 100%;
  background: var(--text-color);
}

.banner__fill-circle_position_left {
  width: 10px;
  top: 705px;
  right: 9px;
}

.banner__fill-circle_position_right {
  width: 16px;
  top: -8px;
  left: 567px;
}

.banner__smoke-bg {
  width: 1953px;
  position: absolute;
  top: 187px;
  left: -1019px;
  mix-blend-mode: plus-lighter;
  z-index: -2;
}

@media (max-width: 1150px) {
  .banner {
    padding-top: 502px;
  }

  .banner__img {
    width: 658px;
    top: -100px;
  }

  .banner__logo {
    width: 138px;
    height: 230px;
  }
}

@media (max-width: 900px) {
  .banner {
    padding-top: 266px;
  }

  .banner__img {
    width: 390px;
    top: -40px;
  }

  .banner__logo {
    width: 96px;
    height: 160px;
  }

  .banner__arrow-box {
    width: 26px;
    height: 26px;
    margin-top: 50px;
  }

  .banner__bg {
    width: 390px;
  }

  .banner__circle-box {
    width: 688px;
  }

  .banner__circle-box_position_left {
    top: -452px;
    left: -472px;
  }

  .banner__circle-box_position_center {
    display: none;
  }

  .banner__circle-box_position_right {
    top: -232px;
    left: 38px;
  }

  .banner__fill-circle_position_left {
    top: 636px;
    right: 164px;
  }

  .banner__fill-circle_position_right {
    display: none;
  }

  .banner__smoke-bg {
    width: 771px;
    top: 502px;
    right: -352px;
    left: unset;
  }
}
