@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url(./assets/fonts/Mayak/stylesheet.css);

body {
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-optical-sizing: auto;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote {
  padding: 0;
  margin: 0;
  color: var(--text-color);
  white-space: pre-line;
  font-style: normal;
}

a {
  font-style: normal;
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
}

button {
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-style: normal;
  color: var(--text-color);
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

:root {
  --text-color: #FFFFFF;
  --accent-color: #FFB31F;
  --neutral-color: #D6A65D;
  --light-neutral-color: #F1CE89;
  --bg-color: #151522;
  --container-bg: #262637;
  --border-color: #D5D5FF;
  --border-opacity-color: rgba(255, 255, 255, 0.10);
  --grey-color: #777786;
  --opacity-bg: rgba(255, 255, 255, 0.05);
  --neutral-icons-color: #9393A4;
}
