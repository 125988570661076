.location {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 120px;
}

.location__logo {
  width: 60px;
  height: 132px;
}

.location__logo-fill {
  fill: var(--light-neutral-color);
}

.location__logo-stroke {
  stroke: var(--accent-color);
}

.location__logo-main-fill {
  fill: var(--grey-color);
}

.location__logo-main-stroke {
  stroke: var(--grey-color);
}

.location__title {
  color: var(--neutral-color);
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  line-height: 0.8;
  letter-spacing: 1.2px;
  padding-top: 30px;
}

.location__subtitle {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.8px;
  padding-top: 20px;
}

.location__map {
  width: 100%;
  height: 560px;
  background: lightgray;
  margin-top: 40px;
}

.location__circle-box {
  width: 1440px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

.location__circle {
  width: 1166px;
  aspect-ratio: 1/1;
  position: absolute;
  top: -80px;
  left: -382px;
  border-radius: 100%;
  border: 0.4px solid var(--text-color);
  opacity: 0.3;
}

.location__bg {
  width: 1953px;
  position: absolute;
  top: -1538px;
  right: -782px;
  mix-blend-mode: plus-lighter;
  z-index: -2;
}

@media (max-width: 1150px) {
  .location__map {
    height: 470px;
  }
}

@media (max-width: 900px) {
  .location {
    margin-top: 50px;
  }

  .location__logo {
    width: 40px;
    height: 88px;
  }

  .location__title {
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: 0.8px;
    padding: 30px 62px 0;
  }

  .location__subtitle {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.56px;
    padding: 12px 62px 0;
  }

  .location__map {
    height: 380px;
    margin-top: 30px;
  }

  .location__circle-box {
    width: 390px;
  }

  .location__circle {
    width: 516px;
    top: -62px;
    left: -333px;
  }

  .location__bg {
    width: 447px;
    top: -149px;
    left: -125px;
  }
}
